import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./sba-approved-lenders.css"
import { Link } from "gatsby"

const AdditionalFunding = () => {
  return (
    <Layout>
      <SEO title="Additional Funding Resources" />
        <div className="container primary-bg">
          <h1 class="small-padding">Available Funding Resources</h1>
          <p className="small-padding">
            We recognize the importance of accessing various funding sources as you recover and work towards developing a sustainable business strategy. Additional funding options from local, state and federal sources, as well as potential grants from foundations, private companies and non-profits, are included below. 
          </p>
          <hr />
          <Link className="full-cta" to="/additional-funding-state-and-federal">
            State & Federal Programs
          </Link>
          <Link className="full-cta" to="/additional-funding-foundations-grants-nonprofits">
            Foundations, Grants &amp; Non-Profits
          </Link>
          <hr />
    
      </div>
    </Layout>
  )
}

export default AdditionalFunding
